import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { useKeyedState } from "./use-keyed-state";

/**
 * Web ストレージの特定のエントリの値を利用するフックです。
 *
 * @param storage ストレージ オブジェクト (`localStorage` or `sessionStorage`)
 * @param key キー
 * @returns 値とセッターのペア
 */
export function useStorageValue(
    storage: Storage,
    key: string,
): [value: string, setValue: Dispatch<SetStateAction<string>>] {
    // キー毎の状態
    const [value, setLocalValue] = useKeyedState(
        key,
        () => storage.getItem(key) ?? "",
    );

    // セッターを定義する
    const setValue = useCallback(
        (newValueOrUpdate: SetStateAction<string>): void => {
            setLocalValue((oldValue) => {
                const newValue =
                    typeof newValueOrUpdate === "function"
                        ? newValueOrUpdate(oldValue)
                        : newValueOrUpdate;

                // 値が変化したらストレージに保存する
                if (newValue !== oldValue) {
                    queueMicrotask(() => {
                        try {
                            if (newValue) {
                                storage.setItem(key, newValue);
                            } else {
                                storage.removeItem(key);
                            }
                        } catch {
                            // 無視する
                        }
                    });
                }

                return newValue;
            });
        },
        [key, setLocalValue, storage],
    );

    // 更新イベントに反応する
    useEffect(() => {
        function listener(event: StorageEvent) {
            if (event.storageArea === storage && event.key === key) {
                setLocalValue(event.newValue ?? "");
            }
        }

        window.addEventListener("storage", listener);
        return () => {
            window.removeEventListener("storage", listener);
        };
    }, [key, setLocalValue, storage]);

    return [value, setValue];
}
